<template>
  <div class="add-assignment-types">
    <contentNotView v-if="!subPermission.store" />
    <b-card v-else :title="$t('g.assignmentTypes/add_assignment_type')">
      <!-- form -->
      <validation-observer ref="addAssignmentTypeForm">
        <b-form>
          <b-row>
            <!-- Name -->
            <b-col md="12">
              <b-form-group
                :label="$t('g.assignmentTypes/assignment_type_name')"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('g.assignmentTypes/assignment_type_name')"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheNameHere')"
                  />
                  <small class="text-danger">{{
                    errors[0]
                      ? $helpers.translateValidations(
                          errors[0],
                          $t("g.assignmentTypes/assignment_type_name")
                        )
                      : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Name -->

            <!-- is_available -->
            <b-col md="4">
              <b-form-group :label="$t('g.status')">
                <validation-provider
                  #default="{ errors }"
                  name="status"
                  rules="required"
                >
                  <b-form-checkbox
                    switch
                    v-model="form_data.is_available"
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ $t("g.assignmentTypes/is_available") }}
                  </b-form-checkbox>
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- is_available -->

            <b-col cols="12">
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
                :disabled="is_submitted"
              >
                <span
                  v-if="is_submitted"
                  class="loading_"
                  data-loading="light"
                />
                <span v-else>{{ $t("g.submit") }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BRow,
} from "bootstrap-vue";
import { required } from "@core/utils/validations/validations";
import contentNotView from "@/components/contentNotView/contentNotView.vue";

export default {
  name: "AddAssignmentTypes",
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormFile,
    contentNotView,
  },
  data() {
    return {
      is_submitted: false,
      form_data: {
        name: null,
        is_available: true,
      },
    };
  },
  computed: {
    subPermission() {
      this.$store.dispatch("GET_PERMISSION", "assignment-types");
      return this.$store.state.userData.sub_Permission;
    },
  },
  methods: {
    validationForm() {
      this.$refs.addAssignmentTypeForm.validate().then((success) => {
        if (success) {
          this.is_submitted = true;
          let formData = new FormData();
          formData.append("name", this.form_data.name);
          formData.append("is_available", this.form_data.is_available ? 1 : 0);

          this.$http
            .post("/admin/assignment-types", formData)
            .then((response) => {
              this.$router.push({ name: "assignmentTypesList" });
              this.$helpers.makeToast(
                "success",
                this.$t("g.assignmentTypes/added_successfully"),
                this.$t("g.assignmentTypes/assignment_type_added_successfully")
              );
            })
            .catch((error) => {
              this.is_submitted = false;
              this.$helpers.handleError(error);
            });
        }
      });
    },
  },
};
</script>
